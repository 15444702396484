@import "../default.scss";

.chs-input {
  border: 0.5px solid #c4c4c4;
  display: flex;
  align-items: center;
  width: 100%;

  &.type-search {
    border-radius: 20px;
    padding: 0 10px;
  }

  &__field {
    height: 40px;
    width: 100%;
    border: 0px;
    padding: 0px 10px;
    background-color: transparent;
  }

  &__textarea {
    width: 100%;
    resize: none;
    outline: none;
    border: 0px;
    padding: 10px;
    background-color: transparent;
  }

  &__select {
    height: 40px;
    width: 100%;
    border: 0px;
    padding: 0px 10px;
    background-color: transparent;

    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &__left-icon {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right-icon {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__feedback {
    width: 100%;
    color: $color-principal;
    text-align: right;
    font-size: 12px;
    margin-top: 5px;
  }
}
