// THEME DECLARATIONS GOES HERE

$height-header: 60px;
$height-footer: 50px;
$width-sidenav: 250px;

$color-principal: #1F9D92;
$color-principal-dark: darken(#1F9D92, 10%);

$color-infos: #FFA500;
$color-infos-dark: #E89806;
$color-infos-light: #FFBC1F;

$color-green: #2AC769;
$color-green-dark: #1AB759;
$color-green-light: #40DD7F;

$color-red: #FB4E4E;
$color-red-dark: #E93C3C;
$color-red-light: #FF6262;

$color-blue: #0396FF;

$color-black: #25282B;
$color-disabled: #A0A4A8;
$color-border: #E8E8E8;
$color-background: #F9F9FA;



a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin-bottom: 0 !important;
}

li {
  list-style: none !important;
}

*:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}

.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  width: 90% !important;
}
.h-95 {
  width: 95% !important;
}

.font-50 {
  font-size: 50% !important;
}
.font-60 {
  font-size: 60% !important;
}
.font-70 {
  font-size: 70% !important;
}
.font-80 {
  font-size: 80% !important;
}
.font-90 {
  font-size: 90% !important;
}
.font-125 {
  font-size: 125% !important;
}
.font-150 {
  font-size: 150% !important;
}

.text-principal {
  color: $color-principal !important;
}

.text-infos {
  color: $color-infos !important;
}
.text-infos-dark {
  color: $color-infos-dark;
}
.text-infos-light {
  color: $color-infos-light;
}

.text-green {
  color: $color-green !important;
}
.text-green-dark {
  color: $color-green-dark;
}
.text-green-light {
  color: $color-green-light;
}

.text-red {
  color: $color-red !important;
}
.text-red-dark {
  color: $color-red-dark;
}
.text-red-light {
  color: $color-red-light;
}

.text-blue {
  color: $color-blue;
}

.text-black {
  color: $color-black !important;
}
.text-disabled {
  color: $color-disabled !important;
}

.bg-principal {
  background-color: $color-principal !important;
}

.bg-infos {
  background-color: $color-infos;
}
.bg-infos-dark {
  background-color: $color-infos-dark;
}
.bg-infos-light {
  background-color: $color-infos-light;
}

.bg-green {
  background-color: $color-green !important;
}
.bg-green-dark {
  background-color: $color-green-dark;
}
.bg-green-light {
  background-color: $color-green-light;
}

.bg-red {
  background-color: $color-red !important;
}
.bg-red-dark {
  background-color: $color-red-dark;
}
.bg-red-light {
  background-color: $color-red-light;
}

.bg-blue {
  background-color: $color-blue;
}

.bg-black {
  background-color: $color-black !important;
}
.bg-disabled {
  background-color: $color-disabled;
}

.border-5 {
  border-radius: 5px !important;
}
.border-10 {
  border-radius: 10px !important;
}
.border-15 {
  border-radius: 15px !important;
}
.border-20 {
  border-radius: 20px !important;
}
.border-25 {
  border-radius: 25px !important;
}
.border-30 {
  border-radius: 30px !important;
}
.border-35 {
  border-radius: 35px !important;
}
.border-40 {
  border-radius: 40px !important;
}
.border-45 {
  border-radius: 45px !important;
}
.border-50 {
  border-radius: 50px !important;
}