// $color-principal: #246f29;
$color-principal: #1f9d92;
$color-principal-one: #decce9a3;
$color-principal-dark: #00261b;
$color-principal-light: #168038;
$color-second: #decce9a3;
$color-danger: #f90000;
$color-warning: #ffb42a;
$color-transparent: rgba(0, 0, 0, 0.07);
$color-gray: #707070;
$color-white: #ffffff;
$color-header: #ffffff;
$color-background: #ffffff;
$color-border: #dddddd;

$height-header: 70px;
$width-sidebar: 300px;

@mixin box-shadow {
  box-shadow: 1px 0 15px 0 #00000010;
}

.shadow-custom {
  box-shadow: 1px 0 15px 0 #00000010 !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  height: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #187269;
  color: white;
}

.react-calendar__tile {
  flex-direction: column;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e2e8f0 !important;

  &.active {
    background-color: #e7fffd !important;
    color: #00261b !important;
    font-weight: bold;
  }
}

.react-calendar__tile--active {
  background-color: #29d2c3 !important;
  color: $color-principal-dark !important;
}

.react-calendar__tile--now {
  background-color: #1f9d92 !important;
  color: white !important;
}

.react-calendar__navigation__arrow {
  background-color: #f7f8fc !important;
  color: #8c96ab !important;
  border-radius: 50%;
}

.react-calendar__navigation button:hover {
  background-color: transparent !important;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

// Resets
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif !important;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
  color: #000000;
  background-color: $color-background;
  transition: all 0.3s ease-in-out;
}

ul {
  margin-bottom: 0 !important;
}

li {
  list-style: none !important;
}

a {
  text-decoration: none !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

*:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  border: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 15px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}

.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  width: 90% !important;
}
.h-95 {
  width: 95% !important;
}

.font-50 {
  font-size: 50% !important;
}
.font-60 {
  font-size: 60% !important;
}
.font-70 {
  font-size: 70% !important;
}
.font-80 {
  font-size: 80% !important;
}
.font-90 {
  font-size: 90% !important;
}
.font-125 {
  font-size: 125% !important;
}
.font-150 {
  font-size: 150% !important;
}

.text-principal {
  color: $color-principal !important;
}

.bg-black {
  background-color: black !important;
}

.bg-principal {
  background-color: $color-principal !important;
}

.bg-principal-pills {
  background-color: #{$color-principal}10;
  color: $color-principal;
}

.bg-blue-dark-pills {
  background-color: #7b61ff;
  color: #ffffff;
}

.text-danger {
  color: $color-danger !important;
}

.bg-danger {
  background-color: $color-danger !important;
}

.bg-transp {
  background-color: $color-transparent !important;
}

.text-second {
  color: $color-second !important;
}

.text-black {
  color: black;
}

.bg-second {
  background-color: $color-second !important;
}

.text-warning {
  color: $color-warning !important;
}

.bg-warning {
  background-color: $color-warning !important;
}

.border-5 {
  border-radius: 5px !important;
}
.border-10 {
  border-radius: 10px !important;
}
.border-15 {
  border-radius: 15px !important;
}
.border-20 {
  border-radius: 20px !important;
}
.border-25 {
  border-radius: 25px !important;
}
.border-30 {
  border-radius: 30px !important;
}
.border-35 {
  border-radius: 35px !important;
}
.border-40 {
  border-radius: 40px !important;
}
.border-45 {
  border-radius: 45px !important;
}
.border-50 {
  border-radius: 50px !important;
}

.line-height-1-5 {
  line-height: 1.5rem;
}

.line-height-2 {
  line-height: 2rem;
}

.line-height-2-5 {
  line-height: 2.5rem;
}

.line-height-3 {
  line-height: 3rem;
}

.word-no-wrap {
  white-space: nowrap;
}

.header {
  height: $height-header;
  width: 100%;
  background-color: $color-white;
}

.header-admin {
  height: $height-header;
  width: 100%;
  z-index: 3;
}

.footer {
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  height: 500px;
}

#breadcrumb {
  list-style: none;
  display: inline-block;
}
#breadcrumb .icon {
  font-size: 14px;
}
#breadcrumb li {
  float: left;
}
#breadcrumb li a {
  color: #fff;
  display: block;
  background: $color-principal-light;
  text-decoration: none;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 5px;
  text-align: center;
  margin-right: 23px;
}
#breadcrumb li:nth-child(even) a {
  background-color: $color-principal-light;
}
#breadcrumb li:nth-child(even) a:before {
  border-color: $color-principal-light;
  border-left-color: transparent;
}
#breadcrumb li:nth-child(even) a:after {
  border-left-color: $color-principal-light;
}
#breadcrumb li:first-child a {
  padding-left: 15px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
}
#breadcrumb li:first-child a:before {
  border: none;
}
#breadcrumb li:last-child a {
  padding-right: 15px;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
}
#breadcrumb li:last-child a:after {
  border: none;
}
#breadcrumb li a:before,
#breadcrumb li a:after {
  content: "";
  position: absolute;
  top: 0;
  border: 0 solid $color-principal-light;
  border-width: 20px 10px;
  width: 0;
  height: 0;
}
#breadcrumb li a:before {
  left: -20px;
  border-left-color: transparent;
}
#breadcrumb li a:after {
  left: 100%;
  border-color: transparent;
  border-left-color: $color-principal-light;
}
#breadcrumb li a:hover {
  background-color: #1fa549;
}
#breadcrumb li a:hover:before {
  border-color: #1fa549;
  border-left-color: transparent;
}
#breadcrumb li a:hover:after {
  border-left-color: #1fa549;
}
#breadcrumb li a:active {
  background-color: #1fa549;
}
#breadcrumb li a:active:before {
  border-color: #1fa549;
  border-left-color: transparent;
}
#breadcrumb li a:active:after {
  border-left-color: #1fa549;
}

.chs {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &-px-responsive {
    padding-left: 50px;
    padding-right: 50px;
  }

  &-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    &-fix {
      height: $height-header;
    }
  }

  &-nav {
    &-logo {
      height: 100%;
      width: 100px;
      margin-left: 0;
      margin-right: 0;
      z-index: 5;

      &_img {
        height: 80%;
        width: 80%;
        object-fit: contain;
      }
    }

    &-menu {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      z-index: 3;
    }

    &-submenu {
      overflow: hidden;
      height: 0px;
      position: absolute;
      top: $height-header;
      background-color: $color-white;
      float: left;
      z-index: 1;
      min-width: 180px;
      border-radius: 0 0 10px 10px;
      transition: background-color 0.3s;
    }

    &-item {
      height: 100%;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover {
        transition: all 0.3s ease-in-out;
      }

      &.active {
        color: $color-principal;
        transition: 0.3s;
      }
    }

    &-subitem {
      height: 50px;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        background-color: $color-transparent;
      }
    }

    &-item:hover &-submenu {
      height: auto;
      border: 0.5px solid #dfdfdf55;
      border-top: 0;
    }

    &-item &-carret {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: 0.3s;
    }

    &-item:hover &-carret {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &-sublink {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #555;
      height: 100%;
      width: 100%;
      font-size: 0.8rem;

      &:hover {
        color: $color-principal;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: inherit;

      &:hover {
        color: $color-principal;
      }

      &.active {
        color: $color-principal;
      }
    }
  }

  &-dropdown {
    position: absolute;
    font-size: 90%;
    top: 80px;
    right: 10px;
    width: 200px;
    height: auto;
    z-index: 1;
    background-color: $color-white;
    visibility: hidden;
    opacity: 0;
    transition: all 0.05s ease-in-out;
    @include box-shadow;
    border-radius: 3px;
    border: 0.5px solid $color-principal-light;

    &__list {
      margin: 0;
      padding: 3px;
      font-weight: bold;
      list-style-type: none;
    }

    &__list-item {
      padding: 10px;
      color: $color-gray;
      border-bottom: 0.5px solid #dddddd;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:hover {
        background-color: $color-principal-light;
        color: white !important;
      }

      &.active {
        color: $color-principal;
        border: 0px;
        background-color: #16803822;
      }
    }

    &__icon {
      width: 35px;
    }

    &__title {
      width: 165px;
    }

    &--active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-hamburger {
    display: none;
    text-align: center;
  }

  &-bar {
    display: block;
    border-radius: 10px;
    width: 25px;
    height: 5px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: $color-second;
  }

  &-slider {
    &-item {
      width: 100%;

      &_img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &-admin {
    &-sidebar {
      background-color: #eafaf8;
      width: $width-sidebar;
      border-radius: 5px;
      height: 100%;
      position: relative;
      padding: 20px;
      padding-bottom: 30px;
      z-index: 10;

      &__brand {
        display: none;
      }

      &-menu {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 190px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-track {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        &::-webkit-scrollbar-corner {
          display: none;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }
      }

      &-item {
        &_title {
          margin-top: 20px;
          height: 50px;
          display: flex;
          align-items: center;
          position: relative;
          padding: 10px 10px 10px 40px;
          border-left: 5px solid white;
          color: #889999;
        }

        cursor: pointer;
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 20px;
        border-radius: 30px;
        margin-bottom: 15px;

        &-link {
          width: 100%;
          height: 100%;
          z-index: 3;
          color: inherit;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            color: black;
          }
        }

        &.active {
          background-color: #29d2c3 !important;
          color: white;
        }

        &:hover {
          background-color: #29d2c311;
        }
      }
    }

    &-navbar {
      display: flex;
      padding: 0px;
      align-items: center;
      justify-content: space-between;
      height: $height-header;
      width: 100%;
      background-color: white;
      z-index: 2;

      &__brand {
        display: none;
      }

      &-menu {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        z-index: 3;
      }

      &-item {
        height: 100%;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &:hover {
          transition: all 0.3s ease-in-out;
        }

        &.active {
          color: $color-principal;
          transition: 0.3s;
        }
      }

      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: inherit;

        &:hover {
          color: $color-principal;
        }

        &.active {
          color: $color-principal;
        }
      }
    }

    &-main {
      width: 100%;
      height: calc(100vh - 70px);
      overflow-y: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .react-calendar__month-view__weekdays__weekday {
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #187269;
    color: white;
  }

  .react-calendar__tile {
    flex-direction: column;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0px solid #e2e8f0 !important;

    &.active {
      background-color: #78e9df !important;
      color: #00261b !important;
      font-weight: bold;
    }
  }

  .footer {
    padding: 30px 10px 10px 10px;
  }

  .header {
    margin-bottom: 50px;
  }

  .chs {
    &-px-responsive {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-navbar {
      justify-content: space-between;
    }

    &-nav {
      &-logo {
        margin: 0;
        width: 100px;
      }

      &-menu {
        z-index: 5;
        background-color: $color-principal;
        overflow: hidden;
        justify-content: flex-start;
        width: 100%;
        overflow-y: scroll;
        text-align: center;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 5px 5px 0px #55555510 !important;

        &.active {
          height: 60vh;
          overflow: scroll;
          transition: 0.1s;
        }
      }

      &-submenu {
        display: none;
        position: unset;
        height: auto;
        width: 100%;
        background-color: $color-white;
        border-radius: 0;
        padding-left: 10px;
      }

      &-item {
        padding: 0;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;

        &:hover {
          background-color: $color-transparent;
          transition: 0.3s;
        }

        &.active {
          color: $color-white;
          background-color: $color-transparent;
          transition: 0.3s;
        }
      }

      &-item:hover &-submenu {
        display: flex;
        flex-direction: column;
        transition: 0.3s;
      }

      &-item &-carret {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: 0.3s;
      }

      &-link {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          color: white;
        }
      }
    }

    &-hamburger {
      display: block;
      cursor: pointer;
      transition: 0.3s;
    }

    &-hamburger.active {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &-admin {
      &-sidebar {
        display: block;
        width: $width-sidebar;
        background-color: white;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 3;
        transform: translateX(320px);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        padding: 20px;
        z-index: 40;
        box-shadow: -10px 0px 20px rgba(2, 13, 12, 0.25);

        &__brand {
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          color: $color-principal;
        }

        &.active {
          transform: translate(0);
          transition: all 0.3s ease-in-out;
        }
      }

      &-navbar {
        display: flex;
        align-items: center;
        padding: 10px;

        &__brand {
          display: flex;
          color: $color-principal;
        }
      }
    }
  }
}
