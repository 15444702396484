@import "../default.scss";

.chs {
  &-checkbox {
    cursor: pointer;
    color: $color-principal;

    &.disabled {
      color: $color-disabled;
    }

    &-principal {
      cursor: pointer;
      color: $color-principal;

      &.disabled {
        color: $color-disabled;
      }
    }

    &-green {
      cursor: pointer;
      color: $color-green;

      &.disabled {
        color: $color-disabled;
      }
    }

    &-infos {
      cursor: pointer;
      color: $color-infos;

      &.disabled {
        color: $color-disabled;
      }
    }

    &-red {
      cursor: pointer;
      color: $color-red;

      &.disabled {
        color: $color-disabled;
      }
    }

    &-blue {
      cursor: pointer;
      color: $color-blue;

      &.disabled {
        color: $color-disabled;
      }
    }

    &-black {
      cursor: pointer;
      color: $color-black;

      &.disabled {
        color: $color-disabled;
      }
    }

    &-gray {
      cursor: pointer;
      color: gray;

      &.disabled {
        color: $color-disabled;
      }
    }
  }

  &-switch {
    height: 20px;
    width: 35px;
    background-color: $color-disabled;
    border-radius: 15px;
    cursor: pointer;
    padding: 3px;
    transition: all 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-sm {
      width: 30px;
      height: 15px;
    }
    &-sm &-indicator {
      width: 10px;
    }
    &-sm &-indicator-type2 {
      width: 10px;
    }
    &-sm &-indicator-type3 {
      width: 10px;
    }

    &-lg {
      height: 25px;
      width: 45px;
    }

    &-lg &-indicator {
      width: 20px;
    }
    &-lg &-indicator-type2 {
      width: 20px;
    }
    &-lg &-indicator-type3 {
      width: 20px;
    }

    &-indicator {
      background-color: white;
      width: 15px;
      height: 100%;
      border-radius: 50%;
    }

    &-indicator-type2 {
      background-color: transparent;
      border-right: 2px solid white;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      border-left: 0px;
      width: 15px;
      height: 100%;
      border-radius: 50%;
    }

    &-indicator-type3 {
      background-color: transparent;
      border-right: 2px solid #CBD5E0;
      border-top: 1px solid #CBD5E0;
      border-bottom: 1px solid #CBD5E0;
      border-left: 0px;
      width: 15px;
      height: 100%;
      border-radius: 50%;
    }

    &.active {
      background-color: $color-principal;
      justify-content: flex-end;
    }

    &-principal {
      &.active {
        background-color: $color-principal;
        justify-content: flex-end;
      }
    }

    &-green {
      &.active {
        background-color: $color-green;
      }
    }

    &-infos {
      &.active {
        background-color: $color-infos;
      }
    }

    &-red {
      &.active {
        background-color: $color-red;
      }
    }

    &-blue {
      &.active {
        background-color: $color-blue;
      }
    }

    &-black {
      &.active {
        background-color: $color-black;
      }
    }

    &-gray {
      &.active {
        background-color: gray;
      }
    }

    &-type2 {
      border: 2px solid white;
      background-color: transparent;
      padding: 0;

      &.active {
        background-color: white;
        justify-content: flex-end;
      }

      &.active .chs-switch-indicator-type2 {
        background-color: $color-principal;
        border: 0px;
      }
    }

    &-type3 {
      border: 2px solid #CBD5E0;
      background-color: transparent;
      padding: 0;

      &.active {
        background-color: $color-principal;
        border: 0px;
        padding: 2px;
        justify-content: flex-end;
      }

      &.active .chs-switch-indicator-type3 {
        background-color: white;
        border: 0px;
      }
    }
  }

  &-switch_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    height: 28px;
    border-radius: 3px;
    overflow: hidden;

    &-sm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70px;
      height: 25px;
      border-radius: 3px;
      overflow: hidden;
    }

    &-md {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80px;
      height: 28px;
      border-radius: 3px;
      overflow: hidden;
    }

    &-lg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      height: 30px;
      border-radius: 3px;
      overflow: hidden;
    }

    &-option {
      cursor: pointer;
      width: 50%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-option_left {
      background-color: $color-principal;
      color: white;
    }
    &-option_right {
      background-color: #{$color-principal}22;
      color: $color-black;
    }
    &.active &-option_left {
      background-color: #{$color-principal}22;
      color: $color-black;
    }
    &.active &-option_right {
      background-color: $color-principal;
      color: white;
    }

    &-green &-option_left {
      background-color: $color-green;
      color: white;
    }
    &-green &-option_right {
      background-color: #{$color-green}22;
      color: $color-black;
    }
    &-green.active &-option_left {
      background-color: #{$color-green}22;
      color: $color-black;
    }
    &-green.active &-option_right {
      background-color: $color-green;
      color: white;
    }

    &-infos &-option_left {
      background-color: $color-infos;
      color: $color-black;
    }
    &-infos &-option_right {
      background-color: #{$color-infos}22;
      color: $color-black;
    }
    &-infos.active &-option_left {
      background-color: #{$color-infos}22;
      color: $color-black;
    }
    &-infos.active &-option_right {
      background-color: $color-infos;
      color: $color-black;
    }

    &-red &-option_left {
      background-color: $color-red;
      color: white;
    }
    &-red &-option_right {
      background-color: #{$color-red}22;
      color: $color-black;
    }
    &-red.active &-option_left {
      background-color: #{$color-red}22;
      color: $color-black;
    }
    &-red.active &-option_right {
      background-color: $color-red;
      color: white;
    }

    &-blue &-option_left {
      background-color: $color-blue;
      color: white;
    }
    &-blue &-option_right {
      background-color: #{$color-blue}22;
      color: $color-black;
    }
    &-blue.active &-option_left {
      background-color: #{$color-blue}22;
      color: $color-black;
    }
    &-blue.active &-option_right {
      background-color: $color-blue;
      color: white;
    }

    &-gray &-option_left {
      background-color: #808080;
      color: white;
    }
    &-gray &-option_right {
      background-color: #80808022;
      color: $color-black;
    }
    &-gray.active &-option_left {
      background-color: #80808022;
      color: $color-black;
    }
    &-gray.active &-option_right {
      background-color: #808080;
      color: white;
    }
  }

  &-progress {
    transition: all 0.2s ease-in-out;
    &-bar {
      height: 10px;
      width: 100%;
      background-color: #{$color-disabled}44;
      border-radius: 3px;
      overflow: hidden;
      transition: all 0.2s ease-in-out;

      &-indicator {
        height: 100%;
        background-color: $color-principal;
        transition: all 0.2s ease-in-out;
      }

      &-infos &-indicator {
        background-color: $color-infos;
      }
      &-green &-indicator {
        background-color: $color-green;
      }
      &-red &-indicator {
        background-color: $color-red;
      }
      &-blue &-indicator {
        background-color: $color-blue;
      }
      &-gray &-indicator {
        background-color: $color-disabled;
      }
    }
  }
}
