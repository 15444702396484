.chs-table {
  &-row {
    padding: 15px !important;
    border: 0px solid #0000 !important;
    overflow: hidden !important;
    font-size: 13px;
  }
  
  &-header {
    padding: 15px !important;
    border: 0px solid #0000 !important;
    overflow: hidden !important;
    font-size: 13px;
  }
}