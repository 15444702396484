@import "../default.scss";

.chs-pill {
  height: 35px;
  min-width: 30px;
  padding: 0 10px;
  outline: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: $color-principal;
  background-color: #{$color-principal}22;
  border-radius: 3px;

  &.bordered {
    border: 0.5px solid #{$color-principal}55;
  }

  &-sm {
    height: 30px;
    min-width: 30px;
  }

  &-md {
    height: 40px;
    min-width: 30px;
  }

  &-lg {
    height: 50px;
    min-width: 30px;
  }

  &-principal {
    color: darken($color-principal, 10%);
    background-color: #{$color-principal}22;

    &-bordered {
      color: darken($color-principal, 10%);
      background-color: #{$color-principal}22;
      border: 0.5px solid #{$color-principal}55;
    }
  }

  &-infos {
    color: darken($color-infos, 10%);
    background-color: #{$color-infos}22;

    &-bordered {
      color: darken($color-infos, 10%);
      background-color: #{$color-infos}22;
      border: 0.5px solid #{$color-infos}55;
    }
  }

  &-green {
    color: darken($color-green, 10%);
    background-color: #{$color-green}22;

    &-bordered {
      color: darken($color-green, 10%);
      background-color: #{$color-green}22;
      border: 0.5px solid #{$color-green}55;
    }
  }

  &-red {
    color: darken($color-red, 10%);
    background-color: #{$color-red}22;

    &-bordered {
      color: darken($color-red, 10%);
      background-color: #{$color-red}22;
      border: 0.5px solid #{$color-red}55;
    }
  }

  &-blue {
    color: darken($color-blue, 10%);
    background-color: #{$color-blue}22;

    &-bordered {
      color: darken($color-blue, 10%);
      background-color: #{$color-blue}22;
      border: 0.5px solid #{$color-blue}55;
    }
  }

  &-black {
    color: darken($color-black, 10%);
    background-color: #{$color-black}22;

    &-bordered {
      color: darken($color-black, 10%);
      background-color: #{$color-black}22;
      border: 0.5px solid #{$color-black}55;
    }
  }

  &-gray {
    color: darken($color-disabled, 10%);
    background-color: #{$color-disabled}22;

    &-bordered {
      color: darken($color-disabled, 10%);
      background-color: #{$color-disabled}22;
      border: 0.5px solid #{$color-disabled}55;
    }
  }
}
