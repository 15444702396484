@import "../default.scss";

.chs-btn {
  height: 35px;
  min-width: 30px;
  padding: 0 10px;
  outline: none;
  border: 0px;
  color: white;
  background-color: $color-principal;
  border-radius: 2px;

  &-sm {
    height: 30px;
    min-width: 30px;
    padding: 0 10px;
    outline: none;
    border: 0px;
    color: white;
    background-color: $color-principal;
    border-radius: 2px;
  }

  &-md {
    height: 40px;
    min-width: 30px;
    padding: 0 10px;
    outline: none;
    border: 0px;
    color: white;
    background-color: $color-principal;
    border-radius: 2px;
  }

  &-lg {
    height: 50px;
    min-width: 30px;
    padding: 0 10px;
    outline: none;
    border: 0px;
    color: white;
    background-color: $color-principal;
    border-radius: 2px;
  }

  &-principal {
    background-color: $color-principal;

    &-outline {
      background-color: transparent;
      color: $color-principal;
      border: 0.7px solid $color-principal;
    }
  }

  &-infos {
    background-color: $color-infos;

    &-outline {
      background-color: transparent;
      color: $color-infos;
      border: 0.7px solid $color-infos;
    }
  }

  &-green {
    background-color: $color-green;

    &-outline {
      background-color: transparent;
      color: $color-green;
      border: 0.7px solid $color-green;
    }
  }

  &-red {
    background-color: $color-red;

    &-outline {
      background-color: transparent;
      color: $color-red;
      border: 0.7px solid $color-red;
    }
  }

  &-blue {
    background-color: $color-blue;

    &-outline {
      background-color: transparent;
      color: $color-blue;
      border: 0.7px solid $color-blue;
    }
  }

  &-black {
    background-color: $color-black;

    &-outline {
      background-color: transparent;
      color: $color-black;
      border: 0.7px solid $color-black;
    }
  }

  &-gray {
    background-color: $color-disabled;

    &-outline {
      background-color: transparent;
      color: $color-disabled;
      border: 0.7px solid $color-disabled;
    }
  }

  &-white {
    background-color: white;

    &-outline {
      background-color: transparent;
      color: $color-black;
      border: 0.7px solid $color-black;
    }
  }
}
